<template>
  <div class="content home flex flex-col gap-4">
    <h1>Shortcut</h1>

    <div class="terms flex flex-col gap-8">
      <div
        v-for="term in terms"
        :key="term.id"
        class="flex flex-col gap-2 md:gap-0 md:flex-row"
      >
        <div class="title text-nowrap">
          {{ term.term_title }}
        </div>
        <div class="description" v-html="term.term_description">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PcnShortcuts',
  components: {},
  data() {
    return {
      terms: [],
    };
  },
  methods: {
    fetchTerms() {
      this.$http
        .get('/terms_pcn')
        .then((res) => {
          this.terms = res.body.terms;
        })
        .catch((err) => {
          alert(`Failed to fetch terms: ${err.response.text}`);
        });
    },
  },
  created() {
    this.fetchTerms();
  },
};
</script>

<style lang="scss">
  .home {
    max-width: 904px;
    margin: 0 auto;
  }
  .home img {
    max-height: 400px;
  }

  .terms .title {
    font-weight: 600;
    width: 180px;
  }

  .terms .description {
    flex: 1;
  }

  .terms a {
    color: #008074;
  }

</style>
